import { Outlet, ReactLocation, Router } from "@tanstack/react-location"
import { useAuth } from "auth"
import { BonzaiProvider } from "bonzai"
import { EnvironmentContext } from "graphql-mock"
import { DialogContainer, TooltipContainer } from "materia"
import { APP_CONFIG } from "optimizer-core"
import { useState } from "react"
import { OptimizeContextProvider } from "ui-tools"
import { ErrorPopup, QueryBoundary } from "utility-components"
import { getQueryParams, parseSearch } from "utils"
import { AppShell } from "./components/AppShell"
import { ConfettiBanner } from "./components/ConfettiBanner"
import { DiplomaUnlockModal } from "./components/DiplomaUnlockModal"
import { GRAPHQL_ENVIRONMENT } from "./constants"
import { OPTIMIZER_URL } from "./env"
import { getRoutes } from "./routes"

const APP = APP_CONFIG.find((app) => app.name === "portal")

export const App = () => (
  <BonzaiProvider>
    <EnvironmentContext.Provider value={GRAPHQL_ENVIRONMENT}>
      <OptimizeContextProvider value={{ app: APP, optimizeUrl: OPTIMIZER_URL }}>
        <TooltipContainer>
          <DialogContainer>
            <QueryBoundary>
              <AppRouter />
            </QueryBoundary>
          </DialogContainer>
        </TooltipContainer>
      </OptimizeContextProvider>
    </EnvironmentContext.Provider>
  </BonzaiProvider>
)

// TODO [WEB-16980] Lock diplomas behind a query param
const isDiplomasEnabled =
  getQueryParams().enableDiplomas?.toUpperCase() === "TRUE"

const AppRouter = () => {
  const { activeSession, isPending, error } = useAuth()

  const [location] = useState(() => new ReactLocation({ parseSearch }))

  if (isPending) return
  if (error) return <ErrorPopup error={error} />

  return (
    <Router
      routes={getRoutes()}
      location={location}
      basepath={activeSession.subdomain}
    >
      <AppShell>
        <Outlet />
        <ConfettiBanner />
        {isDiplomasEnabled && <DiplomaUnlockModal />}
      </AppShell>
    </Router>
  )
}
