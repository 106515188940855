import closeIcon from "assets/icons/closeIcon.svg"
import { invalidateAllQueries } from "bonzai"
import { ForceStateContext, useGraphqlQuery } from "graphql-mock"
import { useModal } from "hooks"
import { Dialog, useWindowWidth } from "materia"
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
  type ReactNode,
} from "react"
import { createPortal } from "react-dom"
import { ErrorPopup } from "utility-components"
import { DEVICE_PLATFORM } from "../../constants"
import { useSubscribeToVuplex } from "../../vuplex/subscribeToVuplex"
import { useConfettiBannerStore } from "../ConfettiBanner/store"
import { ProductModalActivity } from "./ProductModalActivity/ProductModalActivity"
import { ProductModalActivityLoader } from "./ProductModalActivity/ProductModalActivityLoader"
import { ProductModalButtons } from "./ProductModalButtons"
import { ProductModalEventLoader } from "./ProductModalEvent/ProductModalEventLoader"
import { ProductModalLeaderboardLoader } from "./ProductModalLeaderboard/ProductModalLeaderboardLoader"
import { ProductModalMain } from "./ProductModalMain"
import { ProductModalMainLoading } from "./ProductModalMain/loading"
import { query } from "./gql"
import s from "./styles.module.scss"

type ContextType = {
  targetNode: HTMLDivElement | null
}

const ButtonsContext = createContext<ContextType | null>(null)

export const ProductModal = ({
  closeRoute,
  sideContent,
  productId,
}: {
  closeRoute: string
  sideContent: "leaderboard" | "activity" | "event" | "none"
  productId: string
}) => {
  const { data, error, isPending } = useGraphqlQuery(query, {
    productId,
    platform: DEVICE_PLATFORM,
  })

  const { onClose } = useModal(closeRoute, () => {
    invalidateAllQueries()
  })

  useUpdateConfettiBanner(productId)

  useSubscribeToVuplex("CLOSE_PRODUCT_MODALS", onClose)

  if (error) return <ErrorPopup error={error} />

  return (
    <Dialog className={s.modal} ariaLabel="Play product" onClose={onClose}>
      <Content>
        {isPending ? (
          <>
            <ProductModalMainLoading />
            {sideContent !== "none" && <ProductModalActivity.Skeleton />}
          </>
        ) : (
          <>
            <ProductModalMain data={data.productModalMain}>
              <ProductModalButtons data={data.productModalButtons} />
            </ProductModalMain>

            {sideContent === "leaderboard" &&
              !data.currentCompany.portalFeatureFlags.includes(
                "disable_leaderboard"
              ) && (
                <ProductModalLeaderboardLoader productId={Number(productId)} />
              )}

            {sideContent === "activity" && !data.product.isLocked && (
              <ProductModalActivityLoader
                productId={Number(productId)}
                onClose={onClose}
              />
            )}

            {sideContent === "event" && !data.product.isLocked && (
              <ProductModalEventLoader productId={Number(productId)} />
            )}
            <CloseButton onClick={onClose} />
          </>
        )}
      </Content>
    </Dialog>
  )
}

const Content = ({ children }: { children: ReactNode }) => {
  const { isBigWindow } = useWindowWidth()

  const [refState, setRefState] = useState<HTMLDivElement | null>(null)
  const contextValue = useMemo(() => ({ targetNode: refState }), [refState])

  return (
    <ButtonsContext.Provider value={contextValue}>
      <div className={s.content}>
        <div className={s.content__scrollContainer}>
          {children}
          {!isBigWindow && <div ref={setRefState} />}
        </div>
      </div>
    </ButtonsContext.Provider>
  )
}

const Main = ({ children }: PropsWithChildren) => (
  <div className={s.main}>{children}</div>
)

const Side = ({ children }: PropsWithChildren) => (
  <div className={s.side}>{children}</div>
)

const ActionButtons = ({ children }: PropsWithChildren) => {
  const contextValue = useContext(ButtonsContext)
  const { isBigWindow } = useWindowWidth()

  if (!contextValue || isBigWindow) {
    return <>{children}</>
  }

  const { targetNode } = contextValue
  if (!targetNode) return null

  const element = (
    <div className={s.actionButtons}>
      <div className={s.actionButtons__content}>{children}</div>
    </div>
  )

  return createPortal(element, targetNode)
}

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <button className={s.closeButton} onClick={onClick}>
    <img className={s.closeButton__icon} src={closeIcon} alt="Close" />
  </button>
)

ProductModal.Main = Main
ProductModal.Side = Side
ProductModal.ActionButtons = ActionButtons

const useUpdateConfettiBanner = (productId: string) => {
  const forceState = useContext(ForceStateContext)

  useEffect(() => {
    if (forceState !== "live") return

    const { setCurrentProductId } = useConfettiBannerStore.getState()
    setCurrentProductId(productId)
  }, [productId, forceState])
}
