import avatarIcon from "assets/demo/avatar.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query UserWidget {
    currentCompany {
      portalFeatureFlags
    }
    currentUser {
      avatar
      position
      displayName
    }
    userProgressSummary {
      scenarioCompleteCount
      scenarioCompleteMax

      scenarioMandatoryCompleteCount
      scenarioMandatoryCompleteMax

      productCompleteCount
      productCompleteMax

      productStarsCount
      productStarsMax

      productMandatoryCompleteCount
      productMandatoryCompleteMax

      productMandatoryStarsCount
      productMandatoryStarsMax
    }
  }
`)

export const mockUserWidgetData = mockData<Data>({
  currentCompany: {
    portalFeatureFlags: [],
  },
  currentUser: {
    avatar: avatarIcon,
    displayName: "My Username",
    position: "Job Title",
  },
  userProgressSummary: {
    scenarioCompleteCount: 50,
    scenarioCompleteMax: 100,

    scenarioMandatoryCompleteCount: 4,
    scenarioMandatoryCompleteMax: 11,

    productCompleteCount: 98,
    productCompleteMax: 100,

    productStarsCount: 242,
    productStarsMax: 546,

    productMandatoryCompleteCount: 4,
    productMandatoryCompleteMax: 11,

    productMandatoryStarsCount: 0,
    productMandatoryStarsMax: 0,
  },
})

registerMockData(query, mockUserWidgetData())
