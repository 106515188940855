import closeIcon from "assets/icons/closeIcon.svg"
import { DiplomaPreview } from "fiesta-components"
import { useGraphqlQuery } from "graphql-mock"
import { useModal } from "hooks"
import { Dialog } from "materia"
import { type ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { ErrorPopup } from "utility-components"
import { getProductLink } from "../../dataUtilities/productDataUtilities"
import { query, type Data } from "./gql"
import { DiplomaModalLoading } from "./loading"

import s from "./styles.module.scss"

export const DiplomaModal = ({
  productId,
  closeRoute,
}: {
  productId: string
  closeRoute: string
}) => {
  const { onClose } = useModal(closeRoute)

  const { data, error, isPending } = useGraphqlQuery(query, { productId })

  if (error) return <ErrorPopup error={error} />

  return (
    <Shell onClose={onClose}>
      {isPending ? (
        <DiplomaModalLoading />
      ) : (
        <DiplomaModalMain data={data} closeRoute={closeRoute} />
      )}
    </Shell>
  )
}

const DiplomaModalMain = ({
  data,
  closeRoute,
}: {
  data: Data
  closeRoute: string
}) => {
  const { i18n } = useTranslation()
  const { product, currentCompany, currentUser } = data
  const { diplomaConfiguration } = product

  if (diplomaConfiguration === null) throw new Error("Diploma does not exist.")

  const productLink = `${closeRoute}/${getProductLink(
    product.id,
    product.productType
  )}`

  return (
    <DiplomaPreview
      // DATA
      title={diplomaConfiguration.title ?? ""}
      description={diplomaConfiguration.description ?? ""}
      productTitle={product.title ?? ""}
      issueDate={product.deadlineOccurrence?.certifiedAt ?? ""}
      expirationDate={product.deadlineOccurrence?.deadlineDate ?? ""}
      firstName={currentUser.firstName ?? ""}
      lastName={currentUser.lastName ?? ""}
      companyName={currentCompany.name ?? ""}
      companyLogo={currentCompany.logoUrl}
      // CONFIG
      showUserLastName={diplomaConfiguration.showUserLastName}
      showIssueDate={diplomaConfiguration.showIssueDate}
      showProductTitle={diplomaConfiguration.showProductTitle}
      showCompanyName={diplomaConfiguration.showCompanyName}
      showCompanyLogo={diplomaConfiguration.showCompanyLogo}
      showDiplomaDescription={diplomaConfiguration.showDiplomaDescription}
      // META
      isUnlocked={product.progress.certified}
      contentLocale={i18n.language}
      productLink={productLink}
    />
  )
}

const Shell = ({
  children,
  onClose,
}: {
  children: ReactNode
  onClose: () => void
}) => {
  return (
    <Dialog className={s.diplomaModal} onClose={onClose} ariaLabel="Diploma">
      <div className={s.shell}>
        <div className={s.shell__closeButton}>
          <CloseButton onClose={onClose} />
        </div>
        {children}
      </div>
    </Dialog>
  )
}

const CloseButton = ({ onClose }: { onClose: () => void }) => (
  <button className={s.closeButton} onClick={onClose}>
    <img className={s.closeButton__icon} src={closeIcon} alt="Close" />
  </button>
)
