import { ReactComponent as PlayButtonIcon } from "assets/icons/playButton.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { useGraphqlQuery } from "graphql-mock"
import { times } from "lodash-es"
import { ProgressCircle } from "materia"
import { addPlayLinkParams } from "../../tools/addPlayLinkParams"
import { sendOpenProductMessage } from "../../vuplex/sendOpenProductMessage"
import { useVuplexStore } from "../../vuplex/vuplexStore"
import { query, type Data } from "./gql"
import s from "./styles.module.scss"

export const ProductMiniModal = () => {
  const { productId, miniModalIsOpen } = useVuplexStore()

  const { data, error, isPending } = useGraphqlQuery(
    query,
    { productId: String(productId!) },
    { enabled: productId !== undefined && miniModalIsOpen }
  )

  if (!miniModalIsOpen) return
  if (isPending) return
  if (error) {
    // The productId is stored in Skills indefinitely.
    // The product can be deleted or updated since then, and it might not be available anymore.
    // Therefore we just ignore the error.
    return
  }

  const { product } = data
  const { image, title } = product

  return (
    <button className={s.productMiniModal} onClick={() => openProduct(data)}>
      <img className={s.image} src={image} alt="" />
      <div className={s.column}>
        <h2 className={s.title}>{title}</h2>
        <Stars data={data} />
      </div>
      <Play data={data} />
    </button>
  )
}

const Stars = ({ data }: { data: Data }) => {
  const { starsCount, starsMax } = data.product.progress

  return (
    <div className={s.stars} aria-label={`${starsCount} of ${starsMax} stars`}>
      {times(starsMax, (index) => (
        <img
          key={index}
          className={s.stars__star}
          src={index < starsCount ? starFilled : starEmpty}
          alt=""
        />
      ))}
    </div>
  )
}

const Play = ({ data }: { data: Data }) => {
  const { starsCount, starsMax } = data.product.progress
  return (
    <div className={s.play}>
      <div className={s.play__progress}>
        <ProgressCircle
          value={starsCount}
          maxValue={starsMax}
          color="coal"
          backgroundColor="marble"
          stroke={11}
        />
      </div>
      <PlayButtonIcon className={s.play__playIcon} />
    </div>
  )
}

const openProduct = async (data: Data) => {
  const { product } = data
  const link = await addPlayLinkParams({
    link: product.playLinks.appLink,
    useTransferToken: product.useTransferToken,
  })
  if (link) sendOpenProductMessage(product, link)
}
