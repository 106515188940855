import type { Params, Route } from "@tanstack/react-location"
import { Navigate, Outlet } from "@tanstack/react-location"
import { DiplomaModal } from "./components/DiplomaModal"
import { ProductModal } from "./components/ProductModal"
import { VppModalLoader } from "./components/ProductModal/VppModal/VppModalLoader"
import { RedirectView } from "./components/RedirectView"
import { ViewerModal } from "./components/ViewerModal"
import { KpiSalesModalLoader } from "./components/WidgetLayout/KpiSalesModal/KpiSalesModalLoader"
import { WidgetLayoutLoader } from "./components/WidgetLayout/WidgetLayoutLoader"
import { EventsView } from "./views/EventsView"
import { EventHeader } from "./views/EventsView/EventHeader"
import { ExploreView } from "./views/ExploreView"
import { ForYouView } from "./views/ForYouView"
import { RecentActivityWidgetModal } from "./views/ForYouView/RecentActivityWidgetModal"
import { FrontView } from "./views/FrontView"
import { JourneyView } from "./views/JourneyView"
import { LeaderboardViewResponsive } from "./views/LeaderboardView/LeaderboardViewResponsive"
import { NewsPostView } from "./views/NewsPostView"
import { NewsView } from "./views/NewsView/"
import { ProductBundleView } from "./views/ProductBundleView"
import { BundleHeader } from "./views/ProductBundleView/BundleHeader"
import { UserProfileView } from "./views/UserProfileView"

export const getRoutes = (): Route[] => [
  {
    path: "/",
    element: (
      <WidgetLayoutLoader>
        <FrontView />,
      </WidgetLayoutLoader>
    ),
  },

  {
    path: "/for-you",
    element: (
      <WidgetLayoutLoader>
        <ForYouView />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/for-you"),
  },

  {
    path: "/journey",
    element: (
      <WidgetLayoutLoader>
        <JourneyView />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/journey"),
  },

  {
    path: "/explore",
    element: (
      <WidgetLayoutLoader>
        <ExploreView />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/explore"),
  },

  {
    path: "/leaderboards",
    element: (
      <WidgetLayoutLoader showUserInfoHeader={false}>
        <LeaderboardViewResponsive />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/leaderboards"),
  },

  {
    path: "/news/post/:newsPostId",
    element: async ({ params }) => <NewsPostView id={params.newsPostId!} />,
  },

  {
    path: "/news",
    element: (
      <WidgetLayoutLoader showUserInfoHeader={false}>
        <NewsView />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/news"),
  },

  {
    path: "/events",
    element: (
      <>
        <EventHeader />
        <WidgetLayoutLoader showUserInfoHeader={false}>
          <EventsView />
          <Outlet />
        </WidgetLayoutLoader>
      </>
    ),
    children: getChildRoutes(() => `/events`),
  },

  {
    path: "/bundle/:bundleId",
    element: async ({ params }) => (
      <>
        <BundleHeader id={params.bundleId!} />
        <WidgetLayoutLoader showUserInfoHeader={false}>
          <ProductBundleView id={params.bundleId!} />
          <Outlet />
        </WidgetLayoutLoader>
      </>
    ),
    children: getChildRoutes((params) => `/bundle/${params.bundleId}`),
  },

  {
    path: "/me",
    element: (
      <>
        <UserProfileView />
        <Outlet />
      </>
    ),
    children: [
      {
        path: "/diploma/:productId",
        element: async ({ params }) => (
          <DiplomaModal closeRoute="/me" productId={params.productId!} />
        ),
      },
      ...getChildRoutes(() => "/me"),
    ],
  },

  {
    path: "/redirect",
    element: <RedirectView />,
  },

  {
    element: <Navigate to="/" />,
  },
]

const getChildRoutes = (
  getCloseRoute: (params: Params<string>) => string
): Route[] => [
  {
    path: "/product/:productId/diploma",
    element: async ({ params }) => (
      <DiplomaModal
        closeRoute={getCloseRoute(params)}
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/product/:productId/play",
    element: async ({ params }) => (
      <ViewerModal
        productId={params.productId!}
        closeRoute={getCloseRoute(params)}
      />
    ),
  },

  {
    path: "/product/:productId",
    element: async ({ params }) => (
      <ProductModal
        closeRoute={getCloseRoute(params)}
        sideContent="none"
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/sim/:productId/vpp",
    element: async ({ params }) => (
      <VppModalLoader
        productId={params.productId!}
        closeRoute={`${getCloseRoute(params)}/sim/${params.productId}`}
      />
    ),
  },

  {
    path: "/sim/:productId/diploma",
    element: async ({ params }) => (
      <DiplomaModal
        closeRoute={getCloseRoute(params)}
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/sim/:productId/play",
    element: async ({ params }) => (
      <ViewerModal
        productId={params.productId!}
        closeRoute={getCloseRoute(params)}
      />
    ),
  },

  {
    path: "/sim/:productId",
    element: async ({ params }) => (
      <ProductModal
        closeRoute={getCloseRoute(params)}
        sideContent="leaderboard"
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/event/:productId/diploma",
    element: async ({ params }) => (
      <DiplomaModal
        closeRoute={getCloseRoute(params)}
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/event/:productId",
    element: async ({ params }) => (
      <ProductModal
        closeRoute={getCloseRoute(params)}
        sideContent="event"
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/activity/:productId/diploma",
    element: async ({ params }) => (
      <DiplomaModal
        closeRoute={getCloseRoute(params)}
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/activity/:productId",
    element: async ({ params }) => (
      <ProductModal
        closeRoute={getCloseRoute(params)}
        sideContent="activity"
        productId={params.productId!}
      />
    ),
  },

  {
    path: "/kpi-sales",
    element: async ({ params }) => (
      <KpiSalesModalLoader closeRoute={getCloseRoute(params)} />
    ),
  },

  {
    path: "/recent-activity-widget",
    element: async ({ params }) => (
      <RecentActivityWidgetModal closeRoute={getCloseRoute(params)} />
    ),
  },
]
