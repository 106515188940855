import { useSearch } from "@tanstack/react-location"
import starFilled from "assets/icons/starFilled.svg"
import userCompletedIcon from "assets/icons/userCompletedIcon.svg"
import userMandatoryIcon from "assets/icons/userMandatoryIcon.svg"
import { useGraphqlQuery } from "graphql-mock"
import { useQueryParam } from "hooks"
import type { MateriaColor } from "materia"
import { Card, ProgressCircle, Tooltip } from "materia"
import type { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { ErrorPopup, FiestaLink } from "utility-components"
import { toPercent } from "utils"
import { query, type Data } from "./gql"
import { UserWidgetLoading } from "./loading"
import s from "./styles.module.scss"

export const UserWidget = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})
  const [mandatoryQuery] = useQueryParam("mandatory")
  const isMandatory = mandatoryQuery === "true"

  if (isPending) return <UserWidgetLoading />
  if (error) return <ErrorPopup error={error} />

  const { portalFeatureFlags } = data.currentCompany

  return (
    <Card borderRadius="24">
      <div className={s.userWidget}>
        {!portalFeatureFlags.includes("disable_user_profile") && (
          <User data={data} />
        )}
        {!portalFeatureFlags.includes("disable_completed_courses_stars") && (
          <CompletionProgress data={data} isMandatory={isMandatory} />
        )}
        <Filters data={data} />
      </div>
    </Card>
  )
}

const User = ({ data }: { data: Data }) => {
  const { avatar, displayName, position } = data.currentUser
  return (
    <FiestaLink className={s.userWidget__user} to="/me">
      <img className={s.userWidget__avatar} src={avatar} alt="" />
      <h2 className={s.userWidget__userDisplayName}>{displayName}</h2>
      <div className={s.userWidget__position}>{position}</div>
    </FiestaLink>
  )
}

const CompletionProgress = ({
  data,
  isMandatory,
}: {
  isMandatory: boolean
  data: Data
}) => {
  const { t } = useTranslation()

  const {
    productStarsCount,
    productStarsMax,
    productMandatoryStarsCount,
    productMandatoryStarsMax,
  } = data.userProgressSummary

  return (
    <div className={s.progress}>
      <ProgressTooltip
        title={t(isMandatory ? "user.MANDATORY" : "user.COMPLETED")}
        description={t(
          isMandatory ? "user.USER_INFO.mandatory" : "user.USER_INFO.completed"
        )}
      >
        <Completion data={data} isMandatory={isMandatory} />
      </ProgressTooltip>
      <ProgressTooltip
        title={t("user.STARS")}
        description={t("user.USER_INFO.stars")}
      >
        <Stars
          stars={isMandatory ? productMandatoryStarsCount : productStarsCount}
          starsMax={isMandatory ? productMandatoryStarsMax : productStarsMax}
        />
      </ProgressTooltip>
    </div>
  )
}

const Completion = ({
  data,
  isMandatory,
}: {
  isMandatory: boolean
  data: Data
}) => {
  const { t } = useTranslation()

  const {
    scenarioCompleteCount,
    scenarioCompleteMax,
    scenarioMandatoryCompleteCount,
    scenarioMandatoryCompleteMax,
  } = data.userProgressSummary

  const progressValue = isMandatory
    ? scenarioMandatoryCompleteCount
    : scenarioCompleteCount
  const progressMax = isMandatory
    ? scenarioMandatoryCompleteMax
    : scenarioCompleteMax

  return (
    <ProgressContainer
      progressValue={progressValue}
      progressText={`${toPercent(progressValue, progressMax)}%`}
      progressMax={progressMax}
      text={t("user.COMPLETED")}
      color={isMandatory ? "purple-40" : "green-30"}
      backgroundColor={isMandatory ? "pebble" : "green-05"}
      icon={isMandatory ? userMandatoryIcon : userCompletedIcon}
    />
  )
}

const Stars = ({ stars, starsMax }: { stars: number; starsMax: number }) => {
  const { t } = useTranslation()

  return (
    <ProgressContainer
      progressValue={stars}
      progressMax={starsMax}
      progressText={`${stars}/${starsMax}`}
      text={t("user.STARS")}
      color="yellow-50"
      backgroundColor="yellow-05"
      icon={starFilled}
    />
  )
}

const ProgressTooltip = ({
  title,
  description,
  children,
  initiallyIsVisible,
}: {
  title: string
  description: string
  children: ReactNode
  initiallyIsVisible?: boolean
}) => {
  return (
    <Tooltip initialIsVisible={initiallyIsVisible}>
      <Tooltip.Trigger>{children}</Tooltip.Trigger>
      <Tooltip.Content>
        <div className={s.progressTooltip}>
          <div className={s.progressTooltip__title}>{title}</div>
          <div className={s.progressTooltip__description}>{description}</div>
        </div>
      </Tooltip.Content>
    </Tooltip>
  )
}

const Filters = ({ data }: { data: Data }) => {
  return (
    <div className={s.filters}>
      <IncompleteFilter data={data} />
      <MandatoryFilter data={data} />
    </div>
  )
}

const MandatoryFilter = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const { productMandatoryCompleteCount, productMandatoryCompleteMax } =
    data.userProgressSummary

  if (productMandatoryCompleteMax === 0) return

  return (
    <Filter
      color="pink"
      progress={`${productMandatoryCompleteCount}/${productMandatoryCompleteMax}`}
      text={t("user.MANDATORY")}
      param="mandatory"
    />
  )
}

const IncompleteFilter = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const { productCompleteCount, productCompleteMax } = data.userProgressSummary
  const incompleteCount = productCompleteMax - productCompleteCount

  return (
    <Filter
      color="blue"
      progress={`${incompleteCount}`}
      text={t("user.INCOMPLETE")}
      param="incomplete"
    />
  )
}

const Filter = ({
  progress,
  text,
  color,
  param,
}: {
  progress: string
  text: string
  color: "blue" | "pink"
  param: "mandatory" | "incomplete"
}) => {
  const searchParams = useSearch()

  const isActive = Boolean(searchParams[param])

  return (
    <FiestaLink
      to="/for-you"
      search={{ [param]: isActive ? undefined : true }}
      className={modifiers(s, "filter", color, { isActive })}
    >
      <div className={s.filter__progress}>{progress}</div>
      <div className={s.filter__text}>{text}</div>
    </FiestaLink>
  )
}

const ProgressContainer = ({
  progressValue,
  progressMax,
  progressText,
  text,
  icon,
  color,
  backgroundColor,
}: {
  progressValue: number
  progressMax: number
  progressText: string
  text: string
  color: MateriaColor
  backgroundColor: MateriaColor
  icon: string
}) => {
  return (
    <div className={s.progressContainer}>
      <div className={s.progressContainer__radialContainer}>
        <ProgressCircle
          value={progressValue}
          color={color}
          maxValue={progressMax}
          backgroundColor={backgroundColor}
        />
        <img className={s.progressContainer__radialIcon} src={icon} alt="" />
      </div>
      <div className={s.progressContainer__number}>{progressText}</div>
      <div className={s.progressContainer__description}>{text}</div>
    </div>
  )
}
