import noSearchResult from "assets/illustrations/noSearch.svg"
import { useInfiniteGraphqlQuery } from "graphql-mock"
import { last } from "lodash-es"
import { useTranslation } from "react-i18next"
import { ErrorPopup, FiestaImage, WhenVisible } from "utility-components"
import type { ProductsInput } from "../../graphql"
import { ProductCard } from "../ProductCard"
import { ProductCardsLoading } from "../ProductCard/loading"
import { ProductList } from "../ProductList"
import { query } from "./gql"
import { FilteredProductsLoading } from "./loading"
import s from "./styles.module.scss"

const ITEMS_PER_PAGE = 18

export const FilteredProducts = ({ input }: { input: ProductsInput }) => {
  const { data, error, isPending, isFetchingNextPage, fetchNextPage } =
    useInfiniteGraphqlQuery(
      query,
      { input, limit: ITEMS_PER_PAGE },
      { getNextPage: (page) => page.products.pageInfo.nextPage }
    )

  if (isPending) return <FilteredProductsLoading count={ITEMS_PER_PAGE} />
  if (error) return <ErrorPopup error={error} />

  const pageInfo = last(data.pages)?.products.pageInfo
  if (!pageInfo) throw new Error("There must be at least one page")

  const products = data.pages.flatMap((page) => page.products.nodes)

  if (products.length === 0) return <NoResults />

  return (
    <>
      <ProductList>
        {products.map((data) => (
          <ProductCard key={data.id} data={data} />
        ))}
        {isFetchingNextPage && (
          <ProductCardsLoading count={pageInfo.nextPageItemCount} />
        )}
      </ProductList>

      <WhenVisible
        key={`${data.pages.length} ${JSON.stringify({ input })}`}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </>
  )
}

const NoResults = () => {
  const { t } = useTranslation()
  return (
    <div className={s.noProducts}>
      <FiestaImage
        alt=""
        sizes="50px"
        src={noSearchResult}
        className={s.noProducts__image}
      />
      {t("filters.NO_PRODUCTS")}
    </div>
  )
}
