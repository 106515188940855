import productImage from "assets/demo/productImage.svg"
import { mockData, type ResultOf } from "graphql-mock"
import { LOREM_IPSUM } from "ui-tools"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof productModalMainFragment>
export type Product = Data

export const productModalMainFragment = graphql(`
  fragment ProductModalMain on Product {
    id
    title
    descriptionLong
    productType
    image
    isLocked
    lockedBy(limit: 1) {
      nodes {
        id
        title
      }
    }

    progress {
      mandatory
      highScore
      certified
      starsCount
      starsMax
    }

    deadlineOccurrence {
      deadlineDate
      status
    }

    diplomaConfiguration {
      id
      title
      description
    }
  }
`)

export const mockProductModalMainData = mockData<Data>({
  id: "1",
  image: productImage,
  productType: "video",
  title: "Product title",
  descriptionLong: `<p><a>link</a> ${LOREM_IPSUM}</p><p>${LOREM_IPSUM}</p>`,
  isLocked: false,
  lockedBy: {
    nodes: [
      {
        id: "1",
        title: "Journey #1",
      },
    ],
  },
  progress: {
    mandatory: false,
    starsCount: 1,
    starsMax: 1,
    highScore: 12345,
    certified: false,
  },
  deadlineOccurrence: {
    deadlineDate: "2024-02-28T06:43:49.022Z",
    status: "upcoming",
  },
  diplomaConfiguration: {
    id: "1",
    title: "Diploma title",
    description: "For unlocking the diploma",
  },
})
